/*
 * Copyright (C) 2012-2020  Online-Go.com
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */


.ActiveGamesList {
    width: 20rem;
    max-width: 90vw;
    font-size: 1.3rem;
    text-align: left;

    .game {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 0.5rem;

        .players {
            .player-row {
                display: flex;
                flex-direction: row;
                align-items: center;
                align-content: flex-start;
                justify-content: flex-start;

                .Avatar {
                    flex: 0;
                }
            }

        }
    }

    .game:hover, .game.active {
        background-color: #ACE5FF;
        cursor: pointer;
    }

    .game.disabled {
        color: gray;
        background-color: transparent;
        cursor: not-allowed;
    }
}
