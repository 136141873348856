/*
 * Copyright (C) 2012-2020  Online-Go.com
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */



#CharacterSelection {
    position: absolute;
    background-color: #eee;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    text-align: center;
    font-size: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-height: 100vh;
    max-width: 100vw;
    overflow: hidden;

    .NameSelection {
        background-color: rgba(120,120,120,0.9);
        color: white;
        border: 2px solid white;
        border-radius: 0.5rem;
        box-shadow: 0 0 0rem 0.5rem rgba(120, 120, 120, 0.9);
        padding: 1rem;
        margin-top: 4rem;

        .title {
            border-bottom: 2px solid white;
            margin: 0.5rem;
            padding-bottom: 0.5rem;
            letter-spacing: 0.4rem;
        }


    }

    .Button.check, button.ok {
        margin: 1rem;
    }
    button.ok  {
        font-size: 1.3rem;
    }

    .signin-out-buttons {
        position: fixed;
        right: 1rem;
        top: 1rem;

        button {
            margin-left: 1rem;
        }
    }
}

