/*
 * Copyright (C) 2012-2020  Online-Go.com
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

.OpponentList-container {
    display: inline-flex;
    justify-content: stretch;
    text-align: left;

    font-size: 1.2rem;

    .OpponentList {
        width: 20rem;
        max-width: 90vw;
    }

    span {
        flex: 0;
        display: flex;
        align-items: center;


        .Avatar {
            flex: 0;
            margin-right: 1rem;

            .Avatar-svg {
                width: 3rem;
                height: 3rem;
                min-width: 3rem;
                min-height: 3rem;
                max-width: 3rem;
                max-height: 3rem;
            }
        }

        &:hover, &.active {
            background-color: #ACE5FF;
            cursor: pointer;
        }

        &.disabled {
            color: gray;
            background-color: transparent;
            cursor: not-allowed;
        }
    }

    +portrait() {
        .ComputerOpponents {
            display: flex;
            flex-wrap: wrap;
            width: auto !important;
            max-width: 100% !important;
            padding: 0.2rem;

            h4 {
                width: 100%;
                margin: 0;
                padding: 0;
                text-align: center;
            }
            .bot {
                margin: 0;
                padding: 0;
                margin-right: 0.5rem;
                margin-left: 0.5rem;
                display: inline-flex;
                white-space: nowrap;
                min-width: 8rem;
            }

            .BotList {
            }
        }
    }
}
