/*
 * Copyright (C) 2012-2020  Online-Go.com
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

@keyframes hover {
    0%, 100% {transform: translateY(0);}
    //50% {transform: translateY(-4px);}
    50% {transform: translateY(-6px);}
}

.Racoon {
    flex: 1;

    display: inline-flex;
    align-items: stretch;
    align-content: stretch;
    justify-content: center;
    
    .Racoon-svg {
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;

        display: inline-block;
        flex: 1;
        background-image: svg-load("../assets/avatars/racoon.svg");
    }

    &.hover {
        animation: hover 4s infinite;
        animation: hover 3s infinite;
        animation-timing-function: ease-in-out;
    }
    
}
