/*
 * Copyright (C) 2012-2020  Online-Go.com
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

font-size-normal= 14px;

/* z indicies */
z = {
    goban: {
        shadow-layer   : 10,
        stone-layer    : 20,
        pen-layer      : 30,
        message        : 40,
        tab-icon       : 160, /* must be above z.dock */
        exit-zenmode   : 45,
        score-details  : 50,
    },
    rocket: 10,
    flame: 5,
    back-button: 900,
    ingamechat: 1000,
    swal: 10000,
    popup-dialog: 20000,

}


/************/
/************/
/************/

@require "global_styl/global.styl";
@require "global_styl/mixins.styl";
@require "../node_modules/goban/src/Goban.styl";
@require "global_styl/backgrounds.styl";
@require "global_styl/buttons.styl";
@require "global_styl/swal.styl";

@require "./components/[A-Z]*/*.styl";
@require "./views/[A-Z]*/*.styl";



button.primary-button {
    margin: 2rem;
    font-size: 2rem;
    background-color: #6CC489;
    border: none;
    cursor: pointer;

    &:hover {
        background-color: #85F2A9
    }
}
