.ChatBubble {
    position: absolute;
    width: 12rem;
    border: 1px solid #ccc;
    border-radius: 0.5rem;
    background-color: rgba(255, 255, 255, 0.8);
    padding: 0.5rem;
    font-size: 1.5rem;
    z-index: z.ingamechat;

    &.opponent {
        left: 10rem;

        +portrait() {
            left: 0;
        }
    }
    &.player {
        right: 10rem;
        +portrait() {
            right: 0;
        }
    }
}
