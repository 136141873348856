/*
 * Copyright (C) 2012-2020  Online-Go.com
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */



#KidsGame {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    display: flex;
    overflow: hidden;
    background-color: #333;

    +landscape() {
        flex-direction: row;
        align-content: center;
        align-items: stretch;
        justify-content: center;
    }

    +portrait() {
        flex-direction: column;
        align-content: center;
        align-items: stretch;
        justify-content: center;
    }


    #opponent-container, #my-container {
        flex: 1;
        min-width: 10rem;
        max-width: 100vw;
        flex-basis: 10rem;
        min-height: 10rem;

        +phonelandscape() {
            min-width: 7rem;
        }


        +portrait() {
            flex-basis: 5rem;
            min-height: 5rem;
        }

        display: flex;
        flex-direction: column;

        +portrait() {
            flex-direction: row;
        }

        align-content: space-around;
        //align-items: stretch;
        //justify-content: space-evenly;

        .username {
            font-size: 1.5rem;
            color: white;
            text-align: center;
        }
    }
    #opponent-container {
    }
    #my-container {
    }
    #board-container {
        flex: 1;
        display: flex;
        align-items: center;
        align-content: center;
        justify-content: center;

        +landscape() {
            flex-basis: 100vh // strive for square
        }
        +portrait() {
            flex-basis: 100vw // strive for square
        }


        .Goban {
            background-color: transparent;
        }
        .Goban > div {
            background: rgba(208, 155, 86, 0.85) !important;
            background-color: rgba(208, 155, 86, 0.85) !important;
            //opacity: 0.8;
        }

    }


    .Goban-container {
        display: inline-flex;
        padding: 1.5rem;
        background: rgba(255, 255, 255, 0.2);
        border: 2px solid #fff;
        border-radius: 0.5rem;
        border-color:rgba(255, 255, 255, 0.5); 

        /*
        +portrait() {
            padding: 0;
            border: none;
            background: none;
        }
        */
    }


    .top-spacer {
        flex: 0;
        display: inline-block;
        min-height: 5rem;
        height: 5rem;

        +portrait() {
            display: none;
        }

        +phonelandscape() {
            display: none;
        }
    }

    .landscape-bottom-buttons, .portrait-bottom-buttons {
    }
    .landscape-bottom-buttons{
        flex: 0;
        display: inline-flex;
        min-height: 5rem;
        height: 5rem;
        border: 2px solid #fff;
        border-color:rgba(255, 255, 255, 0.5); 
        border-top-left-radius: 2rem;
        border-top-right-radius: 2rem;
        margin-left: auto;
        margin-right: auto;
        padding-left: 1rem;
        padding-right: 1rem;
        padding-top: 0.5rem;
        background: rgba(255, 255, 255, 0.2);
        min-width: 5rem;

        +portrait() {
            display: none;
        }
    }

    .portrait-bottom-buttons{
        flex: 0;
        display: inline-flex;
        min-height: 5rem;
        max-width: 90vw;
        height: 5rem;
        border: 2px solid #fff;
        border-color:rgba(255, 255, 255, 0.5); 
        border-top-left-radius: 2rem;
        border-top-right-radius: 2rem;
        margin-left: auto;
        margin-right: auto;
        padding-left: 0.2rem;
        padding-right: 0.2rem;
        padding-top: 0.5rem;
        background: rgba(255, 255, 255, 0.2);

        +landscape() {
            display: none;
        }

        .left, .right {
            display: inline-flex;
            flex-direction: row;
        }

        .center {
            color: #fff;
            font-weight: bold;
            overflow: hidden;
        }
    }

    .portrait-top-spacer {
        flex: 0;
        height: 2rem;
        min-height: 2rem;
        max-height: 2rem;
        +landscape() {
            display: none;
        }
    }

    .Player {
        flex: 1;
        display: flex;
        flex-direction: column;
        position: relative;

        .label {
            position: absolute;
            left: 0;
            right: 0;
            top: -1.2rem;
            color: white;
            text-align: center;
            width: 100%;
            font-size: 1.2rem;
        }

    }

    .results-button {
        position: fixed;
        top: 1rem;
        right: 1rem;
    }
}



#quit, #menu {
    .fa {
        color: #ccc;
        font-size: 3rem;
        cursor: pointer;

        +portrait() {
            font-size: 2rem;
        }

        &:hover {
            color: #fff;
        }
    }
}


#quit {
    position: absolute;
    top: 0.3rem;
    left: 0.3rem;
}

#menu {
    position: absolute;
    top: 0.3rem;
    right: 1.0rem;
}

.StoneButton {
    display: inline-flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    justify-content: center;
    margin-left: 0.5rem
    margin-right: 0.5rem
    +portrait() {
        margin-left: 0.2rem
        margin-right: 0.2rem
    }

    .button-text {
        max-width: 6rem;
        +portrait() {
            max-width: 4rem;
        }
        word-wrap: break-word;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 12px;
        color: #fff;
        align-text: center;

        &.disabled {
            color: #888;
        }
    }


}

.AnimatedStoneCapture {
    position: fixed;
    z-index: 9999;

}
