

    //@media only screen and (max-width: 1020px){
    //@media only screen and (min-width: 1021px)

portrait()
    @media (orientation: portrait) {
        {block}
    }

landscape()
    @media (orientation: landscape) {
        {block}
    }

phonelandscape()
    @media (orientation: landscape) and (max-width: 800px) {
        {block}
    }
