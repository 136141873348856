/*
 * Copyright (C) 2012-2020  Online-Go.com
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

.avatar-aquatic-63 { background-image: url("/1.0/avatars/aquatic/63.svg"); }
.avatar-aquatic-15 { background-image: url("/1.0/avatars/aquatic/15.svg"); }
.avatar-aquatic-21 { background-image: url("/1.0/avatars/aquatic/21.svg"); }
.avatar-aquatic-35 { background-image: url("/1.0/avatars/aquatic/35.svg"); }
.avatar-aquatic-40 { background-image: url("/1.0/avatars/aquatic/40.svg"); }
.avatar-aquatic-4 { background-image: url("/1.0/avatars/aquatic/4.svg"); }
.avatar-aquatic-43 { background-image: url("/1.0/avatars/aquatic/43.svg"); }
.avatar-aquatic-27 { background-image: url("/1.0/avatars/aquatic/27.svg"); }
.avatar-aquatic-20 { background-image: url("/1.0/avatars/aquatic/20.svg"); }
.avatar-aquatic-5 { background-image: url("/1.0/avatars/aquatic/5.svg"); }
.avatar-aquatic-9 { background-image: url("/1.0/avatars/aquatic/9.svg"); }
.avatar-aquatic-60 { background-image: url("/1.0/avatars/aquatic/60.svg"); }
.avatar-aquatic-62 { background-image: url("/1.0/avatars/aquatic/62.svg"); }
.avatar-aquatic-57 { background-image: url("/1.0/avatars/aquatic/57.svg"); }
.avatar-aquatic-6 { background-image: url("/1.0/avatars/aquatic/6.svg"); }
.avatar-aquatic-17 { background-image: url("/1.0/avatars/aquatic/17.svg"); }
.avatar-aquatic-56 { background-image: url("/1.0/avatars/aquatic/56.svg"); }
.avatar-aquatic-16 { background-image: url("/1.0/avatars/aquatic/16.svg"); }
.avatar-aquatic-29 { background-image: url("/1.0/avatars/aquatic/29.svg"); }
.avatar-aquatic-18 { background-image: url("/1.0/avatars/aquatic/18.svg"); }
.avatar-aquatic-33 { background-image: url("/1.0/avatars/aquatic/33.svg"); }
.avatar-aquatic-54 { background-image: url("/1.0/avatars/aquatic/54.svg"); }
.avatar-aquatic-53 { background-image: url("/1.0/avatars/aquatic/53.svg"); }
.avatar-aquatic-46 { background-image: url("/1.0/avatars/aquatic/46.svg"); }
.avatar-aquatic-12 { background-image: url("/1.0/avatars/aquatic/12.svg"); }
.avatar-aquatic-38 { background-image: url("/1.0/avatars/aquatic/38.svg"); }
.avatar-aquatic-3 { background-image: url("/1.0/avatars/aquatic/3.svg"); }
.avatar-aquatic-24 { background-image: url("/1.0/avatars/aquatic/24.svg"); }
.avatar-aquatic-50 { background-image: url("/1.0/avatars/aquatic/50.svg"); }
.avatar-aquatic-19 { background-image: url("/1.0/avatars/aquatic/19.svg"); }
.avatar-aquatic-30 { background-image: url("/1.0/avatars/aquatic/30.svg"); }
.avatar-aquatic-31 { background-image: url("/1.0/avatars/aquatic/31.svg"); }
.avatar-aquatic-26 { background-image: url("/1.0/avatars/aquatic/26.svg"); }
.avatar-aquatic-52 { background-image: url("/1.0/avatars/aquatic/52.svg"); }
.avatar-aquatic-41 { background-image: url("/1.0/avatars/aquatic/41.svg"); }
.avatar-aquatic-22 { background-image: url("/1.0/avatars/aquatic/22.svg"); }
.avatar-aquatic-14 { background-image: url("/1.0/avatars/aquatic/14.svg"); }
.avatar-aquatic-55 { background-image: url("/1.0/avatars/aquatic/55.svg"); }
.avatar-aquatic-10 { background-image: url("/1.0/avatars/aquatic/10.svg"); }
.avatar-aquatic-51 { background-image: url("/1.0/avatars/aquatic/51.svg"); }
.avatar-aquatic-2 { background-image: url("/1.0/avatars/aquatic/2.svg"); }
.avatar-aquatic-23 { background-image: url("/1.0/avatars/aquatic/23.svg"); }
.avatar-aquatic-64 { background-image: url("/1.0/avatars/aquatic/64.svg"); }
.avatar-aquatic-47 { background-image: url("/1.0/avatars/aquatic/47.svg"); }
.avatar-aquatic-11 { background-image: url("/1.0/avatars/aquatic/11.svg"); }
.avatar-aquatic-25 { background-image: url("/1.0/avatars/aquatic/25.svg"); }
.avatar-aquatic-39 { background-image: url("/1.0/avatars/aquatic/39.svg"); }
.avatar-aquatic-58 { background-image: url("/1.0/avatars/aquatic/58.svg"); }
.avatar-aquatic-45 { background-image: url("/1.0/avatars/aquatic/45.svg"); }
.avatar-aquatic-42 { background-image: url("/1.0/avatars/aquatic/42.svg"); }
.avatar-aquatic-36 { background-image: url("/1.0/avatars/aquatic/36.svg"); }
.avatar-aquatic-61 { background-image: url("/1.0/avatars/aquatic/61.svg"); }
.avatar-aquatic-13 { background-image: url("/1.0/avatars/aquatic/13.svg"); }
.avatar-aquatic-32 { background-image: url("/1.0/avatars/aquatic/32.svg"); }
.avatar-aquatic-44 { background-image: url("/1.0/avatars/aquatic/44.svg"); }
.avatar-aquatic-37 { background-image: url("/1.0/avatars/aquatic/37.svg"); }
.avatar-aquatic-8 { background-image: url("/1.0/avatars/aquatic/8.svg"); }
.avatar-aquatic-7 { background-image: url("/1.0/avatars/aquatic/7.svg"); }
.avatar-aquatic-48 { background-image: url("/1.0/avatars/aquatic/48.svg"); }
.avatar-aquatic-59 { background-image: url("/1.0/avatars/aquatic/59.svg"); }
.avatar-aquatic-49 { background-image: url("/1.0/avatars/aquatic/49.svg"); }
.avatar-aquatic-34 { background-image: url("/1.0/avatars/aquatic/34.svg"); }
.avatar-aquatic-28 { background-image: url("/1.0/avatars/aquatic/28.svg"); }
.avatar-bird-5 { background-image: url("/1.0/avatars/bird/5.svg"); }
.avatar-bird-35 { background-image: url("/1.0/avatars/bird/35.svg"); }
.avatar-bird-2 { background-image: url("/1.0/avatars/bird/2.svg"); }
.avatar-bird-8 { background-image: url("/1.0/avatars/bird/8.svg"); }
.avatar-bird-23 { background-image: url("/1.0/avatars/bird/23.svg"); }
.avatar-bird-18 { background-image: url("/1.0/avatars/bird/18.svg"); }
.avatar-bird-6 { background-image: url("/1.0/avatars/bird/6.svg"); }
.avatar-bird-34 { background-image: url("/1.0/avatars/bird/34.svg"); }
.avatar-bird-7 { background-image: url("/1.0/avatars/bird/7.svg"); }
.avatar-bird-19 { background-image: url("/1.0/avatars/bird/19.svg"); }
.avatar-bird-14 { background-image: url("/1.0/avatars/bird/14.svg"); }
.avatar-bird-30 { background-image: url("/1.0/avatars/bird/30.svg"); }
.avatar-bird-29 { background-image: url("/1.0/avatars/bird/29.svg"); }
.avatar-bird-20 { background-image: url("/1.0/avatars/bird/20.svg"); }
.avatar-bird-12 { background-image: url("/1.0/avatars/bird/12.svg"); }
.avatar-bird-26 { background-image: url("/1.0/avatars/bird/26.svg"); }
.avatar-bird-31 { background-image: url("/1.0/avatars/bird/31.svg"); }
.avatar-bird-9 { background-image: url("/1.0/avatars/bird/9.svg"); }
.avatar-bird-16 { background-image: url("/1.0/avatars/bird/16.svg"); }
.avatar-bird-4 { background-image: url("/1.0/avatars/bird/4.svg"); }
.avatar-bird-28 { background-image: url("/1.0/avatars/bird/28.svg"); }
.avatar-bird-21 { background-image: url("/1.0/avatars/bird/21.svg"); }
.avatar-bird-13 { background-image: url("/1.0/avatars/bird/13.svg"); }
.avatar-bird-27 { background-image: url("/1.0/avatars/bird/27.svg"); }
.avatar-bird-22 { background-image: url("/1.0/avatars/bird/22.svg"); }
.avatar-bird-10 { background-image: url("/1.0/avatars/bird/10.svg"); }
.avatar-bird-15 { background-image: url("/1.0/avatars/bird/15.svg"); }
.avatar-bird-36 { background-image: url("/1.0/avatars/bird/36.svg"); }
.avatar-bird-17 { background-image: url("/1.0/avatars/bird/17.svg"); }
.avatar-bird-32 { background-image: url("/1.0/avatars/bird/32.svg"); }
.avatar-bird-11 { background-image: url("/1.0/avatars/bird/11.svg"); }
.avatar-bird-25 { background-image: url("/1.0/avatars/bird/25.svg"); }
.avatar-bird-33 { background-image: url("/1.0/avatars/bird/33.svg"); }
.avatar-bird-24 { background-image: url("/1.0/avatars/bird/24.svg"); }
.avatar-bird-38 { background-image: url("/1.0/avatars/bird/38.svg"); }
.avatar-bird-3 { background-image: url("/1.0/avatars/bird/3.svg"); }
.avatar-bird-37 { background-image: url("/1.0/avatars/bird/37.svg"); }
.avatar-fuzzball-177 { background-image: url("/1.0/avatars/fuzzball/177.svg"); }
.avatar-fuzzball-133 { background-image: url("/1.0/avatars/fuzzball/133.svg"); }
.avatar-fuzzball-64 { background-image: url("/1.0/avatars/fuzzball/64.svg"); }
.avatar-fuzzball-144 { background-image: url("/1.0/avatars/fuzzball/144.svg"); }
.avatar-fuzzball-176 { background-image: url("/1.0/avatars/fuzzball/176.svg"); }
.avatar-fuzzball-121 { background-image: url("/1.0/avatars/fuzzball/121.svg"); }
.avatar-fuzzball-65 { background-image: url("/1.0/avatars/fuzzball/65.svg"); }
.avatar-fuzzball-62 { background-image: url("/1.0/avatars/fuzzball/62.svg"); }
.avatar-fuzzball-159 { background-image: url("/1.0/avatars/fuzzball/159.svg"); }
.avatar-fuzzball-129 { background-image: url("/1.0/avatars/fuzzball/129.svg"); }
.avatar-fuzzball-57 { background-image: url("/1.0/avatars/fuzzball/57.svg"); }
.avatar-fuzzball-150 { background-image: url("/1.0/avatars/fuzzball/150.svg"); }
.avatar-fuzzball-68 { background-image: url("/1.0/avatars/fuzzball/68.svg"); }
.avatar-fuzzball-115 { background-image: url("/1.0/avatars/fuzzball/115.svg"); }
.avatar-fuzzball-12 { background-image: url("/1.0/avatars/fuzzball/12.svg"); }
.avatar-fuzzball-146 { background-image: url("/1.0/avatars/fuzzball/146.svg"); }
.avatar-fuzzball-5 { background-image: url("/1.0/avatars/fuzzball/5.svg"); }
.avatar-fuzzball-9 { background-image: url("/1.0/avatars/fuzzball/9.svg"); }
.avatar-fuzzball-39 { background-image: url("/1.0/avatars/fuzzball/39.svg"); }
.avatar-fuzzball-145 { background-image: url("/1.0/avatars/fuzzball/145.svg"); }
.avatar-fuzzball-124 { background-image: url("/1.0/avatars/fuzzball/124.svg"); }
.avatar-fuzzball-122 { background-image: url("/1.0/avatars/fuzzball/122.svg"); }
.avatar-fuzzball-63 { background-image: url("/1.0/avatars/fuzzball/63.svg"); }
.avatar-fuzzball-11 { background-image: url("/1.0/avatars/fuzzball/11.svg"); }
.avatar-fuzzball-169 { background-image: url("/1.0/avatars/fuzzball/169.svg"); }
.avatar-fuzzball-45 { background-image: url("/1.0/avatars/fuzzball/45.svg"); }
.avatar-fuzzball-56 { background-image: url("/1.0/avatars/fuzzball/56.svg"); }
.avatar-fuzzball-178 { background-image: url("/1.0/avatars/fuzzball/178.svg"); }
.avatar-fuzzball-123 { background-image: url("/1.0/avatars/fuzzball/123.svg"); }
.avatar-fuzzball-148 { background-image: url("/1.0/avatars/fuzzball/148.svg"); }
.avatar-fuzzball-26 { background-image: url("/1.0/avatars/fuzzball/26.svg"); }
.avatar-fuzzball-28 { background-image: url("/1.0/avatars/fuzzball/28.svg"); }
.avatar-fuzzball-97 { background-image: url("/1.0/avatars/fuzzball/97.svg"); }
.avatar-fuzzball-88 { background-image: url("/1.0/avatars/fuzzball/88.svg"); }
.avatar-fuzzball-60 { background-image: url("/1.0/avatars/fuzzball/60.svg"); }
.avatar-fuzzball-6 { background-image: url("/1.0/avatars/fuzzball/6.svg"); }
.avatar-fuzzball-74 { background-image: url("/1.0/avatars/fuzzball/74.svg"); }
.avatar-fuzzball-23 { background-image: url("/1.0/avatars/fuzzball/23.svg"); }
.avatar-fuzzball-171 { background-image: url("/1.0/avatars/fuzzball/171.svg"); }
.avatar-fuzzball-21 { background-image: url("/1.0/avatars/fuzzball/21.svg"); }
.avatar-fuzzball-81 { background-image: url("/1.0/avatars/fuzzball/81.svg"); }
.avatar-fuzzball-154 { background-image: url("/1.0/avatars/fuzzball/154.svg"); }
.avatar-fuzzball-94 { background-image: url("/1.0/avatars/fuzzball/94.svg"); }
.avatar-fuzzball-135 { background-image: url("/1.0/avatars/fuzzball/135.svg"); }
.avatar-fuzzball-51 { background-image: url("/1.0/avatars/fuzzball/51.svg"); }
.avatar-fuzzball-31 { background-image: url("/1.0/avatars/fuzzball/31.svg"); }
.avatar-fuzzball-138 { background-image: url("/1.0/avatars/fuzzball/138.svg"); }
.avatar-fuzzball-158 { background-image: url("/1.0/avatars/fuzzball/158.svg"); }
.avatar-fuzzball-84 { background-image: url("/1.0/avatars/fuzzball/84.svg"); }
.avatar-fuzzball-15 { background-image: url("/1.0/avatars/fuzzball/15.svg"); }
.avatar-fuzzball-119 { background-image: url("/1.0/avatars/fuzzball/119.svg"); }
.avatar-fuzzball-46 { background-image: url("/1.0/avatars/fuzzball/46.svg"); }
.avatar-fuzzball-38 { background-image: url("/1.0/avatars/fuzzball/38.svg"); }
.avatar-fuzzball-156 { background-image: url("/1.0/avatars/fuzzball/156.svg"); }
.avatar-fuzzball-157 { background-image: url("/1.0/avatars/fuzzball/157.svg"); }
.avatar-fuzzball-40 { background-image: url("/1.0/avatars/fuzzball/40.svg"); }
.avatar-fuzzball-153 { background-image: url("/1.0/avatars/fuzzball/153.svg"); }
.avatar-fuzzball-1 { background-image: url("/1.0/avatars/fuzzball/1.svg"); }
.avatar-fuzzball-92 { background-image: url("/1.0/avatars/fuzzball/92.svg"); }
.avatar-fuzzball-108 { background-image: url("/1.0/avatars/fuzzball/108.svg"); }
.avatar-fuzzball-55 { background-image: url("/1.0/avatars/fuzzball/55.svg"); }
.avatar-fuzzball-172 { background-image: url("/1.0/avatars/fuzzball/172.svg"); }
.avatar-fuzzball-162 { background-image: url("/1.0/avatars/fuzzball/162.svg"); }
.avatar-fuzzball-44 { background-image: url("/1.0/avatars/fuzzball/44.svg"); }
.avatar-fuzzball-112 { background-image: url("/1.0/avatars/fuzzball/112.svg"); }
.avatar-fuzzball-99 { background-image: url("/1.0/avatars/fuzzball/99.svg"); }
.avatar-fuzzball-109 { background-image: url("/1.0/avatars/fuzzball/109.svg"); }
.avatar-fuzzball-47 { background-image: url("/1.0/avatars/fuzzball/47.svg"); }
.avatar-fuzzball-149 { background-image: url("/1.0/avatars/fuzzball/149.svg"); }
.avatar-fuzzball-86 { background-image: url("/1.0/avatars/fuzzball/86.svg"); }
.avatar-fuzzball-139 { background-image: url("/1.0/avatars/fuzzball/139.svg"); }
.avatar-fuzzball-36 { background-image: url("/1.0/avatars/fuzzball/36.svg"); }
.avatar-fuzzball-30 { background-image: url("/1.0/avatars/fuzzball/30.svg"); }
.avatar-fuzzball-118 { background-image: url("/1.0/avatars/fuzzball/118.svg"); }
.avatar-fuzzball-25 { background-image: url("/1.0/avatars/fuzzball/25.svg"); }
.avatar-fuzzball-161 { background-image: url("/1.0/avatars/fuzzball/161.svg"); }
.avatar-fuzzball-167 { background-image: url("/1.0/avatars/fuzzball/167.svg"); }
.avatar-fuzzball-52 { background-image: url("/1.0/avatars/fuzzball/52.svg"); }
.avatar-fuzzball-93 { background-image: url("/1.0/avatars/fuzzball/93.svg"); }
.avatar-fuzzball-132 { background-image: url("/1.0/avatars/fuzzball/132.svg"); }
.avatar-fuzzball-77 { background-image: url("/1.0/avatars/fuzzball/77.svg"); }
.avatar-fuzzball-32 { background-image: url("/1.0/avatars/fuzzball/32.svg"); }
.avatar-fuzzball-22 { background-image: url("/1.0/avatars/fuzzball/22.svg"); }
.avatar-fuzzball-113 { background-image: url("/1.0/avatars/fuzzball/113.svg"); }
.avatar-fuzzball-17 { background-image: url("/1.0/avatars/fuzzball/17.svg"); }
.avatar-fuzzball-103 { background-image: url("/1.0/avatars/fuzzball/103.svg"); }
.avatar-fuzzball-170 { background-image: url("/1.0/avatars/fuzzball/170.svg"); }
.avatar-fuzzball-7 { background-image: url("/1.0/avatars/fuzzball/7.svg"); }
.avatar-fuzzball-20 { background-image: url("/1.0/avatars/fuzzball/20.svg"); }
.avatar-fuzzball-70 { background-image: url("/1.0/avatars/fuzzball/70.svg"); }
.avatar-fuzzball-33 { background-image: url("/1.0/avatars/fuzzball/33.svg"); }
.avatar-fuzzball-141 { background-image: url("/1.0/avatars/fuzzball/141.svg"); }
.avatar-fuzzball-27 { background-image: url("/1.0/avatars/fuzzball/27.svg"); }
.avatar-fuzzball-89 { background-image: url("/1.0/avatars/fuzzball/89.svg"); }
.avatar-fuzzball-41 { background-image: url("/1.0/avatars/fuzzball/41.svg"); }
.avatar-fuzzball-24 { background-image: url("/1.0/avatars/fuzzball/24.svg"); }
.avatar-fuzzball-152 { background-image: url("/1.0/avatars/fuzzball/152.svg"); }
.avatar-fuzzball-19 { background-image: url("/1.0/avatars/fuzzball/19.svg"); }
.avatar-fuzzball-4 { background-image: url("/1.0/avatars/fuzzball/4.svg"); }
.avatar-fuzzball-85 { background-image: url("/1.0/avatars/fuzzball/85.svg"); }
.avatar-fuzzball-110 { background-image: url("/1.0/avatars/fuzzball/110.svg"); }
.avatar-fuzzball-37 { background-image: url("/1.0/avatars/fuzzball/37.svg"); }
.avatar-fuzzball-173 { background-image: url("/1.0/avatars/fuzzball/173.svg"); }
.avatar-fuzzball-80 { background-image: url("/1.0/avatars/fuzzball/80.svg"); }
.avatar-fuzzball-29 { background-image: url("/1.0/avatars/fuzzball/29.svg"); }
.avatar-fuzzball-131 { background-image: url("/1.0/avatars/fuzzball/131.svg"); }
.avatar-fuzzball-90 { background-image: url("/1.0/avatars/fuzzball/90.svg"); }
.avatar-fuzzball-142 { background-image: url("/1.0/avatars/fuzzball/142.svg"); }
.avatar-fuzzball-78 { background-image: url("/1.0/avatars/fuzzball/78.svg"); }
.avatar-fuzzball-8 { background-image: url("/1.0/avatars/fuzzball/8.svg"); }
.avatar-fuzzball-130 { background-image: url("/1.0/avatars/fuzzball/130.svg"); }
.avatar-fuzzball-160 { background-image: url("/1.0/avatars/fuzzball/160.svg"); }
.avatar-fuzzball-111 { background-image: url("/1.0/avatars/fuzzball/111.svg"); }
.avatar-fuzzball-143 { background-image: url("/1.0/avatars/fuzzball/143.svg"); }
.avatar-fuzzball-49 { background-image: url("/1.0/avatars/fuzzball/49.svg"); }
.avatar-fuzzball-91 { background-image: url("/1.0/avatars/fuzzball/91.svg"); }
.avatar-fuzzball-16 { background-image: url("/1.0/avatars/fuzzball/16.svg"); }
.avatar-fuzzball-137 { background-image: url("/1.0/avatars/fuzzball/137.svg"); }
.avatar-fuzzball-127 { background-image: url("/1.0/avatars/fuzzball/127.svg"); }
.avatar-fuzzball-87 { background-image: url("/1.0/avatars/fuzzball/87.svg"); }
.avatar-fuzzball-163 { background-image: url("/1.0/avatars/fuzzball/163.svg"); }
.avatar-fuzzball-3 { background-image: url("/1.0/avatars/fuzzball/3.svg"); }
.avatar-fuzzball-34 { background-image: url("/1.0/avatars/fuzzball/34.svg"); }
.avatar-fuzzball-71 { background-image: url("/1.0/avatars/fuzzball/71.svg"); }
.avatar-fuzzball-61 { background-image: url("/1.0/avatars/fuzzball/61.svg"); }
.avatar-fuzzball-69 { background-image: url("/1.0/avatars/fuzzball/69.svg"); }
.avatar-fuzzball-54 { background-image: url("/1.0/avatars/fuzzball/54.svg"); }
.avatar-fuzzball-59 { background-image: url("/1.0/avatars/fuzzball/59.svg"); }
.avatar-fuzzball-14 { background-image: url("/1.0/avatars/fuzzball/14.svg"); }
.avatar-fuzzball-35 { background-image: url("/1.0/avatars/fuzzball/35.svg"); }
.avatar-fuzzball-43 { background-image: url("/1.0/avatars/fuzzball/43.svg"); }
.avatar-fuzzball-58 { background-image: url("/1.0/avatars/fuzzball/58.svg"); }
.avatar-fuzzball-96 { background-image: url("/1.0/avatars/fuzzball/96.svg"); }
.avatar-fuzzball-147 { background-image: url("/1.0/avatars/fuzzball/147.svg"); }
.avatar-fuzzball-95 { background-image: url("/1.0/avatars/fuzzball/95.svg"); }
.avatar-fuzzball-116 { background-image: url("/1.0/avatars/fuzzball/116.svg"); }
.avatar-fuzzball-79 { background-image: url("/1.0/avatars/fuzzball/79.svg"); }
.avatar-fuzzball-75 { background-image: url("/1.0/avatars/fuzzball/75.svg"); }
.avatar-fuzzball-107 { background-image: url("/1.0/avatars/fuzzball/107.svg"); }
.avatar-fuzzball-179 { background-image: url("/1.0/avatars/fuzzball/179.svg"); }
.avatar-fuzzball-82 { background-image: url("/1.0/avatars/fuzzball/82.svg"); }
.avatar-fuzzball-165 { background-image: url("/1.0/avatars/fuzzball/165.svg"); }
.avatar-fuzzball-140 { background-image: url("/1.0/avatars/fuzzball/140.svg"); }
.avatar-fuzzball-164 { background-image: url("/1.0/avatars/fuzzball/164.svg"); }
.avatar-fuzzball-101 { background-image: url("/1.0/avatars/fuzzball/101.svg"); }
.avatar-fuzzball-83 { background-image: url("/1.0/avatars/fuzzball/83.svg"); }
.avatar-fuzzball-166 { background-image: url("/1.0/avatars/fuzzball/166.svg"); }
.avatar-fuzzball-104 { background-image: url("/1.0/avatars/fuzzball/104.svg"); }
.avatar-fuzzball-175 { background-image: url("/1.0/avatars/fuzzball/175.svg"); }
.avatar-fuzzball-136 { background-image: url("/1.0/avatars/fuzzball/136.svg"); }
.avatar-fuzzball-120 { background-image: url("/1.0/avatars/fuzzball/120.svg"); }
.avatar-fuzzball-100 { background-image: url("/1.0/avatars/fuzzball/100.svg"); }
.avatar-fuzzball-66 { background-image: url("/1.0/avatars/fuzzball/66.svg"); }
.avatar-fuzzball-18 { background-image: url("/1.0/avatars/fuzzball/18.svg"); }
.avatar-fuzzball-53 { background-image: url("/1.0/avatars/fuzzball/53.svg"); }
.avatar-fuzzball-72 { background-image: url("/1.0/avatars/fuzzball/72.svg"); }
.avatar-fuzzball-102 { background-image: url("/1.0/avatars/fuzzball/102.svg"); }
.avatar-fuzzball-125 { background-image: url("/1.0/avatars/fuzzball/125.svg"); }
.avatar-fuzzball-67 { background-image: url("/1.0/avatars/fuzzball/67.svg"); }
.avatar-fuzzball-98 { background-image: url("/1.0/avatars/fuzzball/98.svg"); }
.avatar-fuzzball-73 { background-image: url("/1.0/avatars/fuzzball/73.svg"); }
.avatar-fuzzball-10 { background-image: url("/1.0/avatars/fuzzball/10.svg"); }
.avatar-fuzzball-128 { background-image: url("/1.0/avatars/fuzzball/128.svg"); }
.avatar-fuzzball-134 { background-image: url("/1.0/avatars/fuzzball/134.svg"); }
.avatar-fuzzball-126 { background-image: url("/1.0/avatars/fuzzball/126.svg"); }
.avatar-fuzzball-168 { background-image: url("/1.0/avatars/fuzzball/168.svg"); }
.avatar-fuzzball-76 { background-image: url("/1.0/avatars/fuzzball/76.svg"); }
.avatar-fuzzball-117 { background-image: url("/1.0/avatars/fuzzball/117.svg"); }
.avatar-fuzzball-13 { background-image: url("/1.0/avatars/fuzzball/13.svg"); }
.avatar-fuzzball-48 { background-image: url("/1.0/avatars/fuzzball/48.svg"); }
.avatar-fuzzball-155 { background-image: url("/1.0/avatars/fuzzball/155.svg"); }
.avatar-fuzzball-105 { background-image: url("/1.0/avatars/fuzzball/105.svg"); }
.avatar-fuzzball-106 { background-image: url("/1.0/avatars/fuzzball/106.svg"); }
.avatar-fuzzball-114 { background-image: url("/1.0/avatars/fuzzball/114.svg"); }
.avatar-fuzzball-174 { background-image: url("/1.0/avatars/fuzzball/174.svg"); }
.avatar-fuzzball-2 { background-image: url("/1.0/avatars/fuzzball/2.svg"); }
.avatar-fuzzball-42 { background-image: url("/1.0/avatars/fuzzball/42.svg"); }
.avatar-fuzzball-151 { background-image: url("/1.0/avatars/fuzzball/151.svg"); }
.avatar-wisdom-27 { background-image: url("/1.0/avatars/wisdom/27.svg"); }
.avatar-wisdom-36 { background-image: url("/1.0/avatars/wisdom/36.svg"); }
.avatar-wisdom-8 { background-image: url("/1.0/avatars/wisdom/8.svg"); }
.avatar-wisdom-67 { background-image: url("/1.0/avatars/wisdom/67.svg"); }
.avatar-wisdom-54 { background-image: url("/1.0/avatars/wisdom/54.svg"); }
.avatar-wisdom-86 { background-image: url("/1.0/avatars/wisdom/86.svg"); }
.avatar-wisdom-30 { background-image: url("/1.0/avatars/wisdom/30.svg"); }
.avatar-wisdom-51 { background-image: url("/1.0/avatars/wisdom/51.svg"); }
.avatar-wisdom-35 { background-image: url("/1.0/avatars/wisdom/35.svg"); }
.avatar-wisdom-64 { background-image: url("/1.0/avatars/wisdom/64.svg"); }
.avatar-wisdom-28 { background-image: url("/1.0/avatars/wisdom/28.svg"); }
.avatar-wisdom-87 { background-image: url("/1.0/avatars/wisdom/87.svg"); }
.avatar-wisdom-53 { background-image: url("/1.0/avatars/wisdom/53.svg"); }
.avatar-wisdom-46 { background-image: url("/1.0/avatars/wisdom/46.svg"); }
.avatar-wisdom-78 { background-image: url("/1.0/avatars/wisdom/78.svg"); }
.avatar-wisdom-56 { background-image: url("/1.0/avatars/wisdom/56.svg"); }
.avatar-wisdom-19 { background-image: url("/1.0/avatars/wisdom/19.svg"); }
.avatar-wisdom-73 { background-image: url("/1.0/avatars/wisdom/73.svg"); }
.avatar-wisdom-97 { background-image: url("/1.0/avatars/wisdom/97.svg"); }
.avatar-wisdom-71 { background-image: url("/1.0/avatars/wisdom/71.svg"); }
.avatar-wisdom-93 { background-image: url("/1.0/avatars/wisdom/93.svg"); }
.avatar-wisdom-33 { background-image: url("/1.0/avatars/wisdom/33.svg"); }
.avatar-wisdom-80 { background-image: url("/1.0/avatars/wisdom/80.svg"); }
.avatar-wisdom-34 { background-image: url("/1.0/avatars/wisdom/34.svg"); }
.avatar-wisdom-66 { background-image: url("/1.0/avatars/wisdom/66.svg"); }
.avatar-wisdom-7 { background-image: url("/1.0/avatars/wisdom/7.svg"); }
.avatar-wisdom-11 { background-image: url("/1.0/avatars/wisdom/11.svg"); }
.avatar-wisdom-52 { background-image: url("/1.0/avatars/wisdom/52.svg"); }
.avatar-wisdom-60 { background-image: url("/1.0/avatars/wisdom/60.svg"); }
.avatar-wisdom-17 { background-image: url("/1.0/avatars/wisdom/17.svg"); }
.avatar-wisdom-81 { background-image: url("/1.0/avatars/wisdom/81.svg"); }
.avatar-wisdom-37 { background-image: url("/1.0/avatars/wisdom/37.svg"); }
.avatar-wisdom-49 { background-image: url("/1.0/avatars/wisdom/49.svg"); }
.avatar-wisdom-40 { background-image: url("/1.0/avatars/wisdom/40.svg"); }
.avatar-wisdom-25 { background-image: url("/1.0/avatars/wisdom/25.svg"); }
.avatar-wisdom-39 { background-image: url("/1.0/avatars/wisdom/39.svg"); }
.avatar-wisdom-61 { background-image: url("/1.0/avatars/wisdom/61.svg"); }
.avatar-wisdom-18 { background-image: url("/1.0/avatars/wisdom/18.svg"); }
.avatar-wisdom-58 { background-image: url("/1.0/avatars/wisdom/58.svg"); }
.avatar-wisdom-32 { background-image: url("/1.0/avatars/wisdom/32.svg"); }
.avatar-wisdom-69 { background-image: url("/1.0/avatars/wisdom/69.svg"); }
.avatar-wisdom-70 { background-image: url("/1.0/avatars/wisdom/70.svg"); }
.avatar-wisdom-22 { background-image: url("/1.0/avatars/wisdom/22.svg"); }
.avatar-wisdom-91 { background-image: url("/1.0/avatars/wisdom/91.svg"); }
.avatar-wisdom-23 { background-image: url("/1.0/avatars/wisdom/23.svg"); }
.avatar-wisdom-85 { background-image: url("/1.0/avatars/wisdom/85.svg"); }
.avatar-wisdom-65 { background-image: url("/1.0/avatars/wisdom/65.svg"); }
.avatar-wisdom-77 { background-image: url("/1.0/avatars/wisdom/77.svg"); }
.avatar-wisdom-84 { background-image: url("/1.0/avatars/wisdom/84.svg"); }
.avatar-wisdom-43 { background-image: url("/1.0/avatars/wisdom/43.svg"); }
.avatar-wisdom-59 { background-image: url("/1.0/avatars/wisdom/59.svg"); }
.avatar-wisdom-90 { background-image: url("/1.0/avatars/wisdom/90.svg"); }
.avatar-wisdom-89 { background-image: url("/1.0/avatars/wisdom/89.svg"); }
.avatar-wisdom-4 { background-image: url("/1.0/avatars/wisdom/4.svg"); }
.avatar-wisdom-48 { background-image: url("/1.0/avatars/wisdom/48.svg"); }
.avatar-wisdom-20 { background-image: url("/1.0/avatars/wisdom/20.svg"); }
.avatar-wisdom-6 { background-image: url("/1.0/avatars/wisdom/6.svg"); }
.avatar-wisdom-24 { background-image: url("/1.0/avatars/wisdom/24.svg"); }
.avatar-wisdom-21 { background-image: url("/1.0/avatars/wisdom/21.svg"); }
.avatar-wisdom-2 { background-image: url("/1.0/avatars/wisdom/2.svg"); }
.avatar-wisdom-45 { background-image: url("/1.0/avatars/wisdom/45.svg"); }
.avatar-wisdom-55 { background-image: url("/1.0/avatars/wisdom/55.svg"); }
.avatar-wisdom-92 { background-image: url("/1.0/avatars/wisdom/92.svg"); }
.avatar-wisdom-15 { background-image: url("/1.0/avatars/wisdom/15.svg"); }
.avatar-wisdom-47 { background-image: url("/1.0/avatars/wisdom/47.svg"); }
.avatar-wisdom-9 { background-image: url("/1.0/avatars/wisdom/9.svg"); }
.avatar-wisdom-10 { background-image: url("/1.0/avatars/wisdom/10.svg"); }
.avatar-wisdom-79 { background-image: url("/1.0/avatars/wisdom/79.svg"); }
.avatar-wisdom-44 { background-image: url("/1.0/avatars/wisdom/44.svg"); }
.avatar-wisdom-72 { background-image: url("/1.0/avatars/wisdom/72.svg"); }
.avatar-wisdom-31 { background-image: url("/1.0/avatars/wisdom/31.svg"); }
.avatar-wisdom-16 { background-image: url("/1.0/avatars/wisdom/16.svg"); }
.avatar-wisdom-98 { background-image: url("/1.0/avatars/wisdom/98.svg"); }
.avatar-wisdom-95 { background-image: url("/1.0/avatars/wisdom/95.svg"); }
.avatar-wisdom-5 { background-image: url("/1.0/avatars/wisdom/5.svg"); }
.avatar-wisdom-63 { background-image: url("/1.0/avatars/wisdom/63.svg"); }
.avatar-wisdom-94 { background-image: url("/1.0/avatars/wisdom/94.svg"); }
.avatar-wisdom-62 { background-image: url("/1.0/avatars/wisdom/62.svg"); }
.avatar-wisdom-29 { background-image: url("/1.0/avatars/wisdom/29.svg"); }
.avatar-wisdom-50 { background-image: url("/1.0/avatars/wisdom/50.svg"); }
.avatar-wisdom-42 { background-image: url("/1.0/avatars/wisdom/42.svg"); }
.avatar-wisdom-88 { background-image: url("/1.0/avatars/wisdom/88.svg"); }
.avatar-wisdom-75 { background-image: url("/1.0/avatars/wisdom/75.svg"); }
.avatar-wisdom-74 { background-image: url("/1.0/avatars/wisdom/74.svg"); }
.avatar-wisdom-82 { background-image: url("/1.0/avatars/wisdom/82.svg"); }
.avatar-wisdom-13 { background-image: url("/1.0/avatars/wisdom/13.svg"); }
.avatar-wisdom-57 { background-image: url("/1.0/avatars/wisdom/57.svg"); }
.avatar-wisdom-3 { background-image: url("/1.0/avatars/wisdom/3.svg"); }
.avatar-wisdom-26 { background-image: url("/1.0/avatars/wisdom/26.svg"); }
.avatar-wisdom-14 { background-image: url("/1.0/avatars/wisdom/14.svg"); }
.avatar-wisdom-76 { background-image: url("/1.0/avatars/wisdom/76.svg"); }
.avatar-wisdom-83 { background-image: url("/1.0/avatars/wisdom/83.svg"); }
.avatar-wisdom-41 { background-image: url("/1.0/avatars/wisdom/41.svg"); }
.avatar-wisdom-38 { background-image: url("/1.0/avatars/wisdom/38.svg"); }
.avatar-wisdom-68 { background-image: url("/1.0/avatars/wisdom/68.svg"); }
.avatar-wisdom-96 { background-image: url("/1.0/avatars/wisdom/96.svg"); }
.avatar-wisdom-12 { background-image: url("/1.0/avatars/wisdom/12.svg"); }
.avatar-robot-114 { background-image: url("/1.0/avatars/robot/114.svg"); }
.avatar-robot-115 { background-image: url("/1.0/avatars/robot/115.svg"); }
.avatar-robot-116 { background-image: url("/1.0/avatars/robot/116.svg"); }
.avatar-robot-117 { background-image: url("/1.0/avatars/robot/117.svg"); }
.avatar-robot-118 { background-image: url("/1.0/avatars/robot/118.svg"); }
.avatar-robot-119 { background-image: url("/1.0/avatars/robot/119.svg"); }
.avatar-robot-120 { background-image: url("/1.0/avatars/robot/120.svg"); }
.avatar-robot-121 { background-image: url("/1.0/avatars/robot/121.svg"); }
.avatar-robot-122 { background-image: url("/1.0/avatars/robot/122.svg"); }
.avatar-robot-123 { background-image: url("/1.0/avatars/robot/123.svg"); }
.avatar-robot-124 { background-image: url("/1.0/avatars/robot/124.svg"); }
.avatar-robot-125 { background-image: url("/1.0/avatars/robot/125.svg"); }
.avatar-robot-126 { background-image: url("/1.0/avatars/robot/126.svg"); }
.avatar-robot-127 { background-image: url("/1.0/avatars/robot/127.svg"); }
.avatar-robot-128 { background-image: url("/1.0/avatars/robot/128.svg"); }
.avatar-robot-129 { background-image: url("/1.0/avatars/robot/129.svg"); }
.avatar-robot-130 { background-image: url("/1.0/avatars/robot/130.svg"); }
.avatar-robot-131 { background-image: url("/1.0/avatars/robot/131.svg"); }
.avatar-robot-132 { background-image: url("/1.0/avatars/robot/132.svg"); }
.avatar-robot-133 { background-image: url("/1.0/avatars/robot/133.svg"); }
.avatar-robot-134 { background-image: url("/1.0/avatars/robot/134.svg"); }
.avatar-robot-135 { background-image: url("/1.0/avatars/robot/135.svg"); }
.avatar-robot-136 { background-image: url("/1.0/avatars/robot/136.svg"); }
.avatar-robot-137 { background-image: url("/1.0/avatars/robot/137.svg"); }
.avatar-robot-138 { background-image: url("/1.0/avatars/robot/138.svg"); }
.avatar-robot-139 { background-image: url("/1.0/avatars/robot/139.svg"); }
.avatar-robot-140 { background-image: url("/1.0/avatars/robot/140.svg"); }
.avatar-robot-141 { background-image: url("/1.0/avatars/robot/141.svg"); }
.avatar-robot-142 { background-image: url("/1.0/avatars/robot/142.svg"); }
.avatar-robot-143 { background-image: url("/1.0/avatars/robot/143.svg"); }
.avatar-robot-144 { background-image: url("/1.0/avatars/robot/144.svg"); }
.avatar-robot-145 { background-image: url("/1.0/avatars/robot/145.svg"); }
.avatar-robot-146 { background-image: url("/1.0/avatars/robot/146.svg"); }
.avatar-robot-147 { background-image: url("/1.0/avatars/robot/147.svg"); }
.avatar-robot-148 { background-image: url("/1.0/avatars/robot/148.svg"); }
.avatar-robot-149 { background-image: url("/1.0/avatars/robot/149.svg"); }
.avatar-robot-151 { background-image: url("/1.0/avatars/robot/151.svg"); }
.avatar-robot-152 { background-image: url("/1.0/avatars/robot/152.svg"); }
.avatar-robot-153 { background-image: url("/1.0/avatars/robot/153.svg"); }
.avatar-robot-154 { background-image: url("/1.0/avatars/robot/154.svg"); }
.avatar-robot-155 { background-image: url("/1.0/avatars/robot/155.svg"); }
.avatar-robot-156 { background-image: url("/1.0/avatars/robot/156.svg"); }
.avatar-robot-158 { background-image: url("/1.0/avatars/robot/158.svg"); }
.avatar-robot-159 { background-image: url("/1.0/avatars/robot/159.svg"); }
.avatar-robot-160 { background-image: url("/1.0/avatars/robot/160.svg"); }
.avatar-robot-161 { background-image: url("/1.0/avatars/robot/161.svg"); }
.avatar-robot-162 { background-image: url("/1.0/avatars/robot/162.svg"); }
.avatar-robot-163 { background-image: url("/1.0/avatars/robot/163.svg"); }
.avatar-robot-165 { background-image: url("/1.0/avatars/robot/165.svg"); }
.avatar-robot-166 { background-image: url("/1.0/avatars/robot/166.svg"); }
.avatar-robot-167 { background-image: url("/1.0/avatars/robot/167.svg"); }
.avatar-robot-168 { background-image: url("/1.0/avatars/robot/168.svg"); }
.avatar-robot-169 { background-image: url("/1.0/avatars/robot/169.svg"); }
.avatar-robot-170 { background-image: url("/1.0/avatars/robot/170.svg"); }
.avatar-robot-171 { background-image: url("/1.0/avatars/robot/171.svg"); }
.avatar-robot-172 { background-image: url("/1.0/avatars/robot/172.svg"); }
.avatar-robot-173 { background-image: url("/1.0/avatars/robot/173.svg"); }
.avatar-robot-174 { background-image: url("/1.0/avatars/robot/174.svg"); }
.avatar-robot-175 { background-image: url("/1.0/avatars/robot/175.svg"); }
.avatar-robot-176 { background-image: url("/1.0/avatars/robot/176.svg"); }
.avatar-robot-177 { background-image: url("/1.0/avatars/robot/177.svg"); }
.avatar-robot-178 { background-image: url("/1.0/avatars/robot/178.svg"); }
.avatar-robot-179 { background-image: url("/1.0/avatars/robot/179.svg"); }
.avatar-robot-180 { background-image: url("/1.0/avatars/robot/180.svg"); }
.avatar-robot-181 { background-image: url("/1.0/avatars/robot/181.svg"); }
.avatar-robot-182 { background-image: url("/1.0/avatars/robot/182.svg"); }
.avatar-robot-183 { background-image: url("/1.0/avatars/robot/183.svg"); }
.avatar-robot-184 { background-image: url("/1.0/avatars/robot/184.svg"); }
.avatar-robot-185 { background-image: url("/1.0/avatars/robot/185.svg"); }
.avatar-robot-186 { background-image: url("/1.0/avatars/robot/186.svg"); }
.avatar-robot-187 { background-image: url("/1.0/avatars/robot/187.svg"); }
.avatar-robot-188 { background-image: url("/1.0/avatars/robot/188.svg"); }
.avatar-robot-189 { background-image: url("/1.0/avatars/robot/189.svg"); }
.avatar-robot-190 { background-image: url("/1.0/avatars/robot/190.svg"); }
.avatar-robot-191 { background-image: url("/1.0/avatars/robot/191.svg"); }
.avatar-robot-192 { background-image: url("/1.0/avatars/robot/192.svg"); }
.avatar-robot-193 { background-image: url("/1.0/avatars/robot/193.svg"); }
.avatar-robot-194 { background-image: url("/1.0/avatars/robot/194.svg"); }
.avatar-robot-195 { background-image: url("/1.0/avatars/robot/195.svg"); }
.avatar-robot-196 { background-image: url("/1.0/avatars/robot/196.svg"); }
.avatar-robot-197 { background-image: url("/1.0/avatars/robot/197.svg"); }
.avatar-robot-198 { background-image: url("/1.0/avatars/robot/198.svg"); }
.avatar-robot-200 { background-image: url("/1.0/avatars/robot/200.svg"); }
.avatar-robot-201 { background-image: url("/1.0/avatars/robot/201.svg"); }
.avatar-robot-206 { background-image: url("/1.0/avatars/robot/206.svg"); }
.avatar-robot-207 { background-image: url("/1.0/avatars/robot/207.svg"); }
.avatar-robot-214 { background-image: url("/1.0/avatars/robot/214.svg"); }
.avatar-robot-215 { background-image: url("/1.0/avatars/robot/215.svg"); }
.avatar-robot-220 { background-image: url("/1.0/avatars/robot/220.svg"); }
.avatar-robot-221 { background-image: url("/1.0/avatars/robot/221.svg"); }
.avatar-robot-228 { background-image: url("/1.0/avatars/robot/228.svg"); }
.avatar-robot-234 { background-image: url("/1.0/avatars/robot/234.svg"); }
.avatar-robot-242 { background-image: url("/1.0/avatars/robot/242.svg"); }
.avatar-robot-248 { background-image: url("/1.0/avatars/robot/248.svg"); }
.avatar-robot-256 { background-image: url("/1.0/avatars/robot/256.svg"); }
.avatar-robot-257 { background-image: url("/1.0/avatars/robot/257.svg"); }
.avatar-robot-258 { background-image: url("/1.0/avatars/robot/258.svg"); }
.avatar-robot-259 { background-image: url("/1.0/avatars/robot/259.svg"); }
.avatar-robot-260 { background-image: url("/1.0/avatars/robot/260.svg"); }
.avatar-robot-261 { background-image: url("/1.0/avatars/robot/261.svg"); }
.avatar-robot-262 { background-image: url("/1.0/avatars/robot/262.svg"); }
.avatar-robot-263 { background-image: url("/1.0/avatars/robot/263.svg"); }
.avatar-robot-264 { background-image: url("/1.0/avatars/robot/264.svg"); }
.avatar-robot-265 { background-image: url("/1.0/avatars/robot/265.svg"); }
.avatar-robot-266 { background-image: url("/1.0/avatars/robot/266.svg"); }
.avatar-robot-267 { background-image: url("/1.0/avatars/robot/267.svg"); }
.avatar-robot-268 { background-image: url("/1.0/avatars/robot/268.svg"); }
.avatar-robot-269 { background-image: url("/1.0/avatars/robot/269.svg"); }
.avatar-robot-270 { background-image: url("/1.0/avatars/robot/270.svg"); }
.avatar-robot-271 { background-image: url("/1.0/avatars/robot/271.svg"); }
.avatar-robot-272 { background-image: url("/1.0/avatars/robot/272.svg"); }
.avatar-robot-274 { background-image: url("/1.0/avatars/robot/274.svg"); }
