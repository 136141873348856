/*
 * Copyright (C) 2012-2020  Online-Go.com
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

@keyframes bowlbounce {
    0%, 100% {transform: translateY(0);}
    //50% {transform: translateY(-4px);}
    50% {transform: translateY(-6px);}
}


.Bowl {
    flex: 1;

    display: inline-flex;
    align-items: center;
    justify-content: center;

    .spacer {
        flex: 1;
        flex-basis: 1rem;
    }

    
    .Bowl-svg-background {
        position: relative;
        background-image: svg-load("../assets/misc/bowl-back.svg");
        background-repeat: no-repeat;
        //background-position: center;
        background-size: contain;

        display: inline-block;
        //flex: 1;
        //min-height: 3rem;
        //min-width: 3rem;
        //width: 50%;
        height: 50%;
        min-height: 3rem;
        min-width: 3rem;
        max-height: 10rem;
        max-width: 10rem;
        aspect-ratio: 1 / 1;

        .Bowl-stone {
            position: absolute;
            top: 0;
            left: 0;
            height: 14%
            aspect-ratio: 1 / 1;

            &.s0 {
                left: 14%;
                top: -3%;
            }
            &.s1 {
                left: 28%;
                top: -2%;
            }
            &.s2 {
                left: 38%;
                top: -4%;
            }
            &.s3 {
                left: 53%;
                top: -2%;
            }
            &.s4 {
                left: 70%;
                top: -3%;
            }
        }

        .Bowl-svg-foreground {
            position: absolute;
            background-image: svg-load("../assets/misc/bowl-front.svg");
            background-repeat: no-repeat;
            z-index: 999;
            width: 100%;
            height: 100%;
            bottom: -4.5%;
            left: 0%;
        }

    }

    &.bouncing {
        animation: bowlbounce 3s infinite;
        animation-timing-function:  steps(15);
        //animation-timing-function:  ease-in-out;
        /*
        .Bowl-stone {
            animation: bowlbounce 3s infinite;
            animation-timing-function:  steps(12);
            &.s0 {
                animation: bowlbounce 4s infinite;
            animation-timing-function:  steps(12);
            }
            &.s1 {
                animation: bowlbounce 3.5s infinite;
            animation-timing-function:  steps(12);
            }
            &.s2 {
                animation: bowlbounce 5s infinite;
            animation-timing-function:  steps(12);
            }
            &.s3 {
                animation: bowlbounce 4.2s infinite;
            animation-timing-function:  steps(12);
            }
            &.s4 {
                animation: bowlbounce 3.4s infinite;
            animation-timing-function:  steps(12);
            }
        }
        */
    }

    .label {
        position: absolute;
        bottom: -1.5rem;
        left: 0;
        right: 0;
        text-align: center;
        font-size: 1.3rem;
        color: white;
    }
}

