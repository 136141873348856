/*
 * Copyright (C) 2012-2020  Online-Go.com
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */


.ChatDialog-container {
    display: flex;
    width: 100vw;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    align-items: center;
    align-content: center;
    justify-content: center;
    z-index: z.popup-dialog;
    background-color: rgba(255,255,255,0.2);
}

results-height=25rem;

.ChatDialog {
    display: inline-flex;
    padding: 0;
    width: results-height * 1.71512;
    height: results-height;
    max-width: 100vw;
    max-height: 100vh;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-image: svg-load("../assets/misc/modal_background.svg");
    opacity: 0.90;
    color: #32529a;
    text-shadow: 0 0 2px #fff;
    font-size: 1.5rem;
    line-height: 1.7rem;
    flex-direction: column;

    +portrait() {
        font-size: 1.0rem;
    }

    .ChatDialog-phrases {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: stretch;
        margin-top: 3rem;
        margin-bottom: 1rem;
        margin-right: 3rem;
        margin-left: 3rem;
        overflow-y: auto;
        overflow-x: hidden;

        .phrase {
            padding: 1rem;
            margin-left: 3.5rem;
            margin-right: 3.5rem;
            width: calc(100% - 7rem);
            text-align: center;
            cursor: pointer;

            &:hover, &:focus, &:active {
                background-color: #f0f0f0;
            }
        }
    }


    .ChatDialog-buttons {
        flex-shrink: 0;

        height: 6rem;
        padding-bottom: 1rem;
        display: flex;
        flex-direction: row;
        justify-content: space-around;


        button {
            display: inline-block;
            width: 10rem;
            height: 3rem;
            cursor: pointer;
            font-size: 1.5rem;
            margin: 0;
        }
    }

    .red-x, .green-check {
        display: inline-block;
        width: 3rem;
        height: 3rem;
        opacity: 0.8;
        cursor: pointer;

        &:hover {
            opacity: 1.0;
        }
    }
}
