.bg-blue, .bg-yellow, .bg-green, .bg-magenta, .bg-red {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.bg-blue {
    background-image: url("/1.0/backgrounds/blue.jpg");
    background-color: #01021E !important;
}
.bg-yellow {
    background-image: url("/1.0/backgrounds/yellow.jpg");
    background-color: #E98B1A !important;
}

.bg-green {
    background-image: url("/1.0/backgrounds/green.jpg");
    background-color: #5FDD39 !important;
}

.bg-magenta {
    background-image: url("/1.0/backgrounds/magenta.jpg");
    background-color: #BF1945 !important;
}

.bg-red {
    background-image: url("/1.0/backgrounds/red.jpg");
    background-color: #4D2D1E !important;
}

