/*
 * Copyright (C) 2012-2020  Online-Go.com
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */



#LearnToPlay {
    position: absolute;
    background-color: #001533;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: stretch;

    +portrait() {
        flex-direction: column;
    }

    .spacer {
        flex-grow: 0;
        flex-shrink: 1;
        +portrait() {
            height: "calc((100vh - 100vw) / 2)" % "";
        }
        +landscape() {
            width: "calc((100vw - 100vh) / 2)" % "";
        }
    }

    .back-background {
        position: absolute;
        display: inline-block;
        margin: auto;
        top: 20%;
        bottom: 20%;
        left: 20%;
        right: 20%;
        /*
        top: 30vh;
        bottom: 30vh;
        left: 30vw;
        right: 30vw;
        */
        background-size: cover;
        background-image: url("/1.0/pages/lessons/planet.jpg") !important;
    }

    .background-container {
        flex-grow: 1;
        position: relative;
        text-align: center;
    }

    .background {
        flex-grow: 1;
        z-index: 1;
        position: absolute;
        display: inline-block;
        margin: auto;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        text-align: center;
        background-image: url("/1.0/pages/lessons/background.svg");
        background-repeat: no-repeat;
    }

    .ChapterButton {
        position: absolute;
        display: inline-flex;
        width: 3rem;
        height: 3rem;
        z-index: 1;
        background-image: svg-load("../assets/pages/lessons/button.svg");
        align-content: center;
        justify-content: center;
        align-items: center;
        font-size: 2rem;
        opacity: 0.8;

        &:hover {
            opacity: 1.0;
            cursor: pointer;
        }

        &.disabled {
            background-image: svg-load("../assets/pages/lessons/active.svg");
            cursor: not-allowed;
        }

        // top bottom
        &.chapter-1 {
            left: "calc(50% - 1.5rem)" % "";
            top: 12%;
        }
        &.chapter-5 {
            left: "calc(50% - 1.5rem)" % "";
            bottom: 12%;
        }

        // left right
        &.chapter-7 {
            top: "calc(50% - 1.5rem)" % "";
            left: 12%;
        }
        &.chapter-3 {
            top: "calc(50% - 1.5rem)" % "";
            right: 11%;
        }

        // ne se
        &.chapter-2 {
            right: 22%
            top: 22%;
        }
        &.chapter-4 {
            right: 22%
            bottom: 22%;
        }

        // nw sw
        &.chapter-6 {
            left: 22%
            bottom: 22%;
        }
        &.chapter-8 {
            left: 22%
            top: 22%;
        }
    }

    .chapter-text {
        position: absolute;
        color: #fff;
    }

    .chapter-1-text {
        left: 42%;
        top: 20%;
        color: rgb(255, 204, 102);
        font-weight: bold;
        text-shadow:
        -1px -1px 0 #000,
         1px -1px 0 #000,
        -1px  1px 0 #000,
         1px  1px 0 #000;
         background-color: white;
         padding: 5px;
         border-radius: 15px;
    }

    .chapter-2-text {
        right: 15%;
        top: 30%;
        color: rgb(255, 204, 102);
        font-weight: bold;
        text-shadow:
        -1px -1px 0 #000,
         1px -1px 0 #000,
        -1px  1px 0 #000,
         1px  1px 0 #000;
         background-color: white;
         padding: 5px;
         border-radius: 15px;
    }

    .chapter-3-text {
        right: 3%;
        top: 55%;
        color: rgb(255, 204, 102);
        font-weight: bold;
        text-shadow:
        -1px -1px 0 #000,
         1px -1px 0 #000,
        -1px  1px 0 #000,
         1px  1px 0 #000;
         background-color: white;
         padding: 5px;
         border-radius: 15px;
    }

    .chapter-4-text {
        right: 19%;
        bottom: 17%;
        color: rgb(255, 204, 102);
        font-weight: bold;
        text-shadow:
        -1px -1px 0 #000,
         1px -1px 0 #000,
        -1px  1px 0 #000,
         1px  1px 0 #000;
         background-color: white;
         padding: 5px;
         border-radius: 15px;
    }

    .chapter-5-text {
        right: 38%;
        bottom: 6%;
        color: rgb(255, 204, 102);
        font-weight: bold;
        text-shadow:
        -1px -1px 0 #000,
         1px -1px 0 #000,
        -1px  1px 0 #000,
         1px  1px 0 #000;
         background-color: white;
         padding: 5px;
         border-radius: 15px;
    }
    .chapter-6-text {
        left: 14%;
        bottom: 17%;
        color: rgb(255, 204, 102);
        font-weight: bold;
        text-shadow:
        -1px -1px 0 #000,
         1px -1px 0 #000,
        -1px  1px 0 #000,
         1px  1px 0 #000;
         background-color: white;
         padding: 5px;
         border-radius: 15px;
    }
    .chapter-7-text {
        left: 5%;
        top: 55%;
        color: rgb(255, 204, 102);
        font-weight: bold;
        text-shadow:
        -1px -1px 0 #000,
         1px -1px 0 #000,
        -1px  1px 0 #000,
         1px  1px 0 #000;
         background-color: white;
         padding: 5px;
         border-radius: 15px;
    }
    .chapter-8-text {
        left: 15%;
        top: 30%;
        color: rgb(255, 204, 102);
        font-weight: bold;
        text-shadow:
        -1px -1px 0 #000,
         1px -1px 0 #000,
        -1px  1px 0 #000,
         1px  1px 0 #000;
         background-color: white;
         padding: 5px;
         border-radius: 15px;
    }
}
