/*
 * Copyright (C) 2012-2020  Online-Go.com
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */


#loading-svg-container {
    position: absolute;
    left: 0;
    top: 0;
}

#main-content {
    display: block;
    //color: var(--text-color);
}

html, body, #main-content {
    height: 100%;
    font-size: font-size-normal;
    -webkit-backface-visibility:  hidden;
    -webkit-tap-highlight-color:  transparent;
    background-color: MISSING;
    user-select: none;
}
html, body, #main-content {
    margin: 0;
    padding: 0;
    min-height: 100%;
}


.space-font {
}
