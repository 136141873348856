/*
 * Copyright (C) 2012-2020  Online-Go.com
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */


.flag-dark-green { background-image: svg-load("../assets/buttons/flag-dark-green.svg"); }
.chat-light-green { background-image: svg-load("../assets/buttons/chat-light-green.svg"); }
.check-dark-green { background-image: svg-load("../assets/buttons/check-dark-green.svg"); }
.right-light-green { background-image: svg-load("../assets/buttons/right-light-green.svg"); }
.right-light { background-image: svg-load("../assets/buttons/right-light.svg"); }
.check-dark { background-image: svg-load("../assets/buttons/check-dark.svg"); }
.check-light { background-image: svg-load("../assets/buttons/check-light.svg"); }
.right-dark-green { background-image: svg-load("../assets/buttons/right-dark-green.svg"); }
.x-light { background-image: svg-load("../assets/buttons/x-light.svg"); }
.x-light-green { background-image: svg-load("../assets/buttons/x-light-green.svg"); }
.x-dark { background-image: svg-load("../assets/buttons/x-dark.svg"); }
.check-light-green { background-image: svg-load("../assets/buttons/check-light-green.svg"); }
.chat-dark-green { background-image: svg-load("../assets/buttons/chat-dark-green.svg"); }
.chat-dark { background-image: svg-load("../assets/buttons/chat-dark.svg"); }
.flag-dark { background-image: svg-load("../assets/buttons/flag-dark.svg"); }
.return-light { background-image: svg-load("../assets/buttons/return-light.svg"); }
.right-dark { background-image: svg-load("../assets/buttons/right-dark.svg"); }
.chat-light { background-image: svg-load("../assets/buttons/chat-light.svg"); }
.x-dark-green { background-image: svg-load("../assets/buttons/x-dark-green.svg"); }
.flag-light-green { background-image: svg-load("../assets/buttons/flag-light-green.svg"); }
.return-dark-green { background-image: svg-load("../assets/buttons/return-dark-green.svg"); }
.return-dark { background-image: svg-load("../assets/buttons/return-dark.svg"); }
.return-light-green { background-image: svg-load("../assets/buttons/return-light-green.svg"); }
.flag-light { background-image: svg-load("../assets/buttons/flag-light.svg"); }
.left-dark { @extend .right-dark; transform: scaleX(-1); }
.left-light { @extend .right-light; transform: scaleX(-1); }
.left-dark-green { @extend .right-dark-green; transform: scaleX(-1); }
.left-light-green { @extend .right-light-green; transform: scaleX(-1); }
.up-dark { @extend .right-dark; transform: rotate(-90deg); }
.up-light { @extend .right-light; transform: rotate(-90deg); }
.up-dark-green { @extend .right-dark-green; transform: rotate(-90deg); }
.up-light-green { @extend .right-light-green; transform: rotate(-90deg); }
.red-x { background-image: svg-load("../assets/buttons/red-x.svg"); }
.green-check{ background-image: svg-load("../assets/buttons/green-check.svg"); }
.refresh-light { background-image: svg-load("../assets/buttons/refresh-light.svg"); }
.refresh-light-green { background-image: svg-load("../assets/buttons/refresh-light-green.svg"); }

.stone-button {
    display: inline-flex;
    border: 1px solid transparent;
    border-radius: 5rem;
    min-height: 3.7rem;
    min-width: 3.7rem;
    max-height: 3.7rem;
    max-width: 3.7rem;
    background-size: cover;

    +portrait() {
        min-height: 3.0rem;
        min-width : 3.0rem;
        max-height: 3.0rem;
        max-width : 3.0rem;
    }

    &:not(.disabled) {
        cursor: pointer;
    }
    &.disabled {
        cursor: not-allowed;
    }

    &.disabled {
        opacity: 0.8;
    }
}

button.check, .stone-button-check {
    @extend .stone-button;
    @extend .check-light;
    &:not(.disabled):hover {
        @extend .check-light-green;
    }
    &:not(.disabled):active {
        @extend .check-dark-green;
    }
}
        
button.x, .stone-button-x {
    @extend .stone-button;
    @extend .x-light;
    &:not(.disabled):hover {
        @extend .x-light-green;
    }
    &:not(.disabled):active {
        @extend .x-dark-green;
    }
}
        
button.flag, .stone-button-flag {
    @extend .stone-button;
    @extend .flag-light;
    &:not(.disabled):hover {
        @extend .flag-light-green;
    }
    &:not(.disabled):active {
        @extend .flag-dark-green;
    }
}
        
button.chat, .stone-button-chat {
    @extend .stone-button;
    @extend .chat-light;
    &:not(.disabled):hover {
        @extend .chat-light-green;
    }
    &:not(.disabled):active {
        @extend .chat-dark-green;
    }
}
        
button.return, .stone-button-return {
    @extend .stone-button;
    @extend .return-light;
    &:not(.disabled):hover {
        @extend .return-light-green;
    }
    &:not(.disabled):active {
        @extend .return-dark-green;
    }
}
        
button.left, .stone-button-left {
    @extend .stone-button;
    @extend .left-light;
    &:not(.disabled):hover {
        @extend .left-light-green;
    }
    &:not(.disabled):active {
        @extend .left-dark-green;
    }
}
        
button.right, .stone-button-right {
    @extend .stone-button;
    @extend .right-light;
    &:not(.disabled):hover {
        @extend .right-light-green;
    }
    &:not(.disabled):active {
        @extend .right-dark-green;
    }
}
        
button.up, .stone-button-up {
    @extend .stone-button;
    @extend .up-light;
    &:not(.disabled):hover {
        @extend .up-light-green;
    }
    &:not(.disabled):active {
        @extend .up-dark-green;
    }
}
        
.stone-button-refresh {
    @extend .stone-button;
    @extend .refresh-light;
    &:not(.disabled):hover {
        @extend .refresh-light-green;
    }
}
