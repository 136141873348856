/*
 * Copyright (C) 2012-2020  Online-Go.com
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */



#Matchmaking {
    position: absolute;
    background-color: #eee;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    text-align: center;
    font-size: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-height: 100vh;
    max-width: 100vw;
    overflow: hidden;



    .outer-container {
        display: flex;
        flex-direction: column;
        color: black;
        align-items: center;
        justify-content: center;
        background-color: rgba(255,255,255,0.8);
        padding: 1rem;
        border-radius: 0.5rem;
        border: 1px solid #fff;
        max-height: 80vh;
        overflow: auto;
    }

    .inner-container {
        display: inline-flex;
        flex-direction: row;
        max-height: 70vh;
        overflow: auto;

        +portrait() {
            flex-direction: column;
        }
    }

    .refresh {
        cursor: pointer;
        margin-left: 1rem;
        margin-right: 1rem;
    }

    .NameSelection {
        font-size: 1.5rem;
        position: relative;

        .refresh {
            font-size: 1.8rem;
            position: absolute;
            left: -1rem;
            top: -0.2rem;
        }
    }

    .NameSelection.refreshing {
        opacity: 0.5;
    }

    .username {
        width: 25rem;
        max-width: 100vw;
        display: inline-flex;
        flex-direction: row;
        align-items: flex-end;
        justify-content: center;
        align-content: flex-end;
    }

    select {
        background-color: rgba(255,255,255,0.5);
    }

    .CharacterManagement {
        .AvatarSelection {
            .Avatar {
                .Avatar-svg {
                    width: 15rem;
                    height: 15rem;
                }
            }
        }

        .NameSelection {
        }
    }

    button.play {
        margin: 2rem;
        font-size: 2rem;
        height: 5rem;
        width: 30rem;
        max-width: 90vw;

        +portrait() {
            margin-bottom: 0;
            margin-top: 0;
        }
    }

    .vs {
        display: inline-flex;
        flex-direction: column;
        font-size: 1rem;
        color: #333;
        margin-left: 1rem;
        margin-right: 1rem;
        min-width: 1px;
        background-color: #333;

        +portrait() {
            flex-direction: row;
            align-items: center;
        }

        .line {
            flex: 1;
            border-left: 1px solid #333;
            themed border-color shade3
            margin-left: 0.35em;

            +portrait() {
                border-top: 1px solid #333;
                margin-right: 0.35em;
            }
        }

    }

    .right, .left {
        display: flex;
        flex-shrink: 0;
        justify-content: stretch;
        align-items: stretch;
        flex-direction: column;
        height: 22rem;
        min-height: 22rem;
        max-height: 22rem;
        overflow: auto;

        +portrait() {
            height: auto;
            min-height: unset;
            max-height: unset;
        }
    }

    h4 {
        font-size: 1.5rem;
        margin: 0;
        padding: 0;
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
    }


    .current-user-container {
        margin-top: 5rem;
        margin-bottom: 1rem;
        border-radius: 0.5rem;
        background-color: rgba(255,255,255,0.8);
        padding: 1rem;
        border: 1px solid #fff;
        display: inline-flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        +portrait() {
            width: 100vw;
        }

        .Avatar {
            flex: 0;
            width: 6rem;
            height: 6rem;
            min-height: 6rem;
            max-height: 6rem;
        }

        .Avatar .Avatar-svg {
            width: 6rem;
            height: 6rem;
            min-height: 6rem;
            max-height: 6rem;
        }
        .current-user-race-name {

        }

        +portrait() {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            margin-bottom: 0;
        }
    }


    .settings {
        display: flex;
        flex-direction: row;
        font-size: 1.4rem;
        align-items: center;
        align-content: center;


        .color-selector {
            margin: 0.4rem;
            display: inline-flex;
            flex-direction: column;
        }
        
        select {
            margin: 1.0rem;
            font-size: 1.4rem;
        }

        label {
            display: inline-flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            margin: 0.5rem;

        }

        img {
            width: 1.5rem;
            height: 1.5rem;
        }
    }

    .sign-in {
        margin-left: 1rem;
    }

}

.Handicap {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;
    margin-bottom: 1rem;
    font-size: 1.5rem;
    color: #333;

    button {
        margin: 1rem;
        padding-left: 1.0rem;
        padding-right: 1.0rem;
        padding-bottom: 0.3rem;
    }
}

.ReceivedChallenge {
    display: flex;
    flex-direction: row;
    font-size: 1.7rem;
    align-items: center;
    justify-content: center;

    .Avatar .Avatar-svg {
        min-width: 2.5rem;
        min-height: 2.5rem;
        height: 8rem;
        width: 8rem;
        
    }

    img {
        width: 1.5rem;
        height: 1.5rem;
    }
}

#Lessons-button {
    position: absolute;
    top: 2.0rem;
    left: 5rem;
    +portrait() {
    top: 1.6rem;
    }
}

+portrait() {
    .landscape {
        display: none;
    }
}
+landscape() {
    .portrait {
        display: none;
    }
}

