/*
 * Copyright (C) 2012-2020  Online-Go.com
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */


.ResultsDialog-container {
    display: flex;
    width: 100vw;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    align-items: center;
    align-content: center;
    justify-content: center;
    z-index: z.popup-dialog;
    background-color: rgba(255,255,255,0.2);
}

results-height=25rem;

.ResultsDialog {
    display: inline-flex;
    padding: 0;
    width: results-height * 1.71512;
    height: results-height;
    max-width: 100vw;
    max-height: 100vh;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-image: svg-load("../assets/misc/modal_background.svg");
    opacity: 0.90;
    color: #32529a;
    text-shadow: 0 0 2px #fff;
    font-size: 1.5rem;
    line-height: 1.7rem;

    +portrait() {
        font-size: 1.0rem;
    }

    flex-direction: column;

    .ResultsDialog-results {
        flex-grow: 1;
        padding-top: 3rem;
        padding-left: 2rem;
        padding-right: 2rem;
        text-align: center;
        flex-direction: row;
        display: flex;
        align-content: stretch;

        .result-text {
            display: block;
            min-height: 1.5rem;
        }

        > .black, > .white {
            flex-grow: 1;

            .stone-avatar-container {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                align-content: center;
                .Avatar {
                    flex: 0;
                }
            }

            .Avatar-svg {
                min-width: 6rem;
                min-height: 6rem;
                width: 6rem;
                height: 6rem;
                max-width: 6rem;
                max-height: 6rem;

                +portrait() {
                    min-width: 5rem;
                    min-height: 5rem;
                    width: 5rem;
                    height: 5rem;
                    max-width: 5rem;
                    max-height: 5rem;
                }
            }
        }

        .score-stones {
            border-bottom: 2px solid;
        }
        .score-total {
            border-top: 2px solid;
        }

        .score >div {
            display: inline-flex;
            flex-direction: row;
            align-items: center;
            align-content: center;
        }

        .stone-svg {
            min-width: 1rem;
            min-height: 1rem;
            width: 1rem;
            height: 1rem;
            max-width: 1rem;
            max-height: 1rem;
            padding-right: 0.3rem;
        }

        .label {
            display: inline-block;
            width: 10rem;
            text-align: left;
            +portrait() {
                width: 7rem;
            }
        }


        .value {
            display: inline-block;
            width: 2rem;
            text-align: right;
            white-space: nowrap;
        }
    }

    .ResultsDialog-buttons {
        flex-shrink: 0;

        height: 6rem;
        padding-bottom: 3rem;
        display: flex;
        flex-direction: row;
        justify-content: space-around;


        button {
            display: inline-block;
            width: 11rem;
            height: 3rem;
            cursor: pointer;
            font-size: 1.5rem;
            white-space: nowrap;
            margin: 0;
        }
    }

    .ResultsDialog-outcome {
        flex-shrink: 0;

        height: 1.5rem;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        align-content: center;

        img {
            width: 1.5rem;
            height: 1.5rem;
            margin-right: 0.5rem;

        }
    }

    .red-x, .green-check {
        display: inline-block;
        width: 3rem;
        height: 3rem;
        opacity: 0.8;
        cursor: pointer;

        &:hover {
            opacity: 1.0;
        }
    }
}
