/*
 * Copyright (C) 2012-2020  Online-Go.com
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */


.Button {
    @extend .stone-button
    display: inline-block;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    width: 2rem;
    height: 2rem;
    cursor: pointer;


    &.right-arrow {
        @extend .right-light;

        &:hover {
            @extend .right-light-green;
        }
        &:active {
            @extend .right-dark-green;
        }
    }

    &.left-arrow {
        @extend .left-light;

        &:hover {
            @extend .left-light-green;
        }
        &:active {
            @extend .left-dark-green;
        }
    }

    &.check {
        @extend .check-light;

        &:hover {
            @extend .check-light-green;
        }
        &:active {
            @extend .check-dark-green;
        }
    }
}
