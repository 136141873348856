/*
 * Copyright (C) 2012-2020  Online-Go.com
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */


.PopupDialog-container {
    display: flex;
    width: 100vw;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    align-items: center;
    align-content: center;
    justify-content: center;
    z-index: z.popup-dialog;
    background-color: rgba(255,255,255,0.2);
}

popup-height=20rem;

.PopupDialog {
    display: inline-flex;
    padding: 0;
    width: popup-height * 1.71512;
    height: popup-height;
    max-width: 100vw;
    max-height: 100vh;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-image: svg-load("../assets/misc/modal_background.svg");
    opacity: 0.93;
    color: #32529a;
    text-shadow: 0 0 2px #fff;
    font-size: 2.5rem;
    line-height: 2.8rem;

    +portrait() {
        font-size: 2rem;
        line-height: 2.3rem;
    }

    flex-direction: column;

    .PopupDialog-text {
        flex-grow: 1;
        padding-top: 3rem;
        padding-left: 3rem;
        padding-right: 3rem;
        text-align: center;

        +portrait() {
            padding-left: 2rem;
            padding-right: 2rem;
        }
    }

    .PopupDialog-buttons {
        flex-shrink: 0;

        height: 6rem;
        padding-bottom: 3rem;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
    }

    .red-x, .green-check {
        display: inline-block;
        width: 3rem;
        height: 3rem;
        opacity: 0.8;
        cursor: pointer;

        &:hover {
            opacity: 1.0;
        }
    }
}
