/*
 * Copyright (C) 2012-2020  Online-Go.com
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */


.Avatar {
    flex: 1;

    display: inline-flex;
    align-items: stretch;
    align-content: stretch;
    justify-content: center;
    
    .Avatar-svg {
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;

        display: inline-block;
        flex: 1;
        min-height: 5vh;
        min-width: 5vw;
        max-height: 15rem;
        max-width: 15rem;
    }
    
}

@require "./avatar_list.styl";
